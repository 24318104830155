import React, { useContext } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import { CurrentView } from '../../entities/templates';
import { PagePropsType, lang } from '../../entities/countryIndexQueries'
import useSetGlobalState from '../../components/hooks/useSetGlobalState'
import Wrapper from '../../components/template/ContainerMax';
import { SiteContext } from '../../Context/site'
import PortableText from '../../components/sanityBlockContents/PortableText'
import Text from '../../components/atoms/Text';
import Accordion from "../../components/molecule/accordion";

const IndexPage:React.FC<PagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  const locale = pageContext && pageContext.locale
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView["trips-previous"]  })
  const { state } = useContext(SiteContext);

  return (
    <Layout seoTitle={page?.metadata?.page_title[state?.lang as lang] as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : ''},
    ]}>
      <Wrapper className="py-12">
      { page?.previousTripIntro && page?.previousTripIntro[state?.lang as lang]
        && 
        <PortableText content={page?.previousTripIntro[state?.lang as lang]}
          className=""
          template={{
            h2: (props: any) => <Text type="H2">{props.children}</Text>,
            normal: (props: any) => <Text type="P" ptype="BASE"  >{props.children}</Text>,
            blockquote: (props: any) => <Text type="P" ptype="BASE">{props.children}</Text>,
          }} />}
        <Accordion />
      </Wrapper>
    </Layout>
)}

export default IndexPage

export const query = graphql`
  query countryTravelInfoPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      heroImage: _rawCountryTravelInformationImage(resolveReferences: {maxDepth: 4})
      travelInformation: _rawTravelInformation(resolveReferences: {maxDepth: 4})
      metadata: _rawCountryTravelInformationMetadata
    }
  }
`
