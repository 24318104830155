import { useEffect, useContext } from 'react'

import { PagePropsType, TitleType, lang } from '../../entities/countryIndexQueries'
import { SiteContext, ACTION_TYPES } from '../../Context/site'
import { CurrentView } from '../../entities/templates';

interface ArgsInterface {
  page: PagePropsType['data']['pageData'];
  locale: PagePropsType['pageContext']['locale'],
  currentView: CurrentView,
  pageContext:  PagePropsType['pageContext']
}

const useSetGlobalState = ({ page, locale, currentView, pageContext }: ArgsInterface) => {
  const { stateDispatch } = useContext(SiteContext);
  
  useEffect(() => {
    const handleSetArrayOfLocale = () => {
      const values = Object.keys(page?.title as TitleType<string>);
      const _locales = (values.filter(item => item !== '_type') as unknown) as lang[];

      _locales.length &&
      stateDispatch &&
      stateDispatch({ 
        type: ACTION_TYPES.SET_ALL_LOCALES,
        key: 'allLocales',
        payload: _locales
      });
    }

    if(stateDispatch) {
      //setLang 
      locale && stateDispatch({ 
        type: ACTION_TYPES.SET_LANG,
        key: 'lang', 
        payload: locale
      });

      //setCurrentView
      currentView && stateDispatch({ 
        type: ACTION_TYPES.SET_CURRENT_VIEW,
        key: 'currentView',
        payload: currentView
      });

      //setCountry
      page?.title && stateDispatch({ 
        type: ACTION_TYPES.SET_COUNTRY,
        key: 'countryName',
        payload: page?.title[locale!]
      });

      //setAllSlugs
      pageContext?.allSlugs && stateDispatch({ 
        type: ACTION_TYPES.SET_ALL_SLUGS,
        key: 'allSlugs',
        payload: pageContext?.allSlugs
      });

      //setSubTitleData
      page?.subTitle && stateDispatch({ 
        type: ACTION_TYPES.SET_SUB_TITLE_DATA,
        key: 'subTitleData',
        payload: page?.subTitle[locale!]
      });

      //setLAngPageImage
      page?.heroImage && stateDispatch({ 
        type: ACTION_TYPES.SET_LANDING_PAGE_IMAGE,
        key: 'landingPageImage',
        payload: page?.heroImage
      });
    }

    page?.title && handleSetArrayOfLocale();
  }, [
    locale, page?.subTitle, page?.title, page?.heroImage,
    pageContext?.allSlugs, stateDispatch, currentView
  ]);
}

export default useSetGlobalState;