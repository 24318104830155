import React, { useState } from 'react';

const Accordion: React.FC = () => {
  const [openArticle, setopenArticle] = useState(1)
  return (
    <section>
      <article className="bg-white shadow-fullSides lg:w-7/12 py-5 px-5 lg:pl-8 lg:pr-0 mb-5" onClick={() => setopenArticle(1)}>
        <div className="lg:w-10/12">
          <h3 className="font-normal mb-0">Lorem ipsum dolor sit.</h3>
          {openArticle === 1 && (
            <div className="my-4">
              <div className="animate__animated animate__fadeIn" id={`menu-${1}`}>
                <p className="leading-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quas perferendis sit eos dolor similique aliquam incidunt, excepturi modi et, odio est quaerat repudiandae consequatur fugiat accusamus aperiam saepe. Maxime!
                  Dolorum facere atque nisi possimus enim et id odit? Earum deserunt alias ratione! Perferendis in enim voluptatem quam nam ex molestiae laudantium, corporis adipisci! Dolorem minus nisi maiores illum aspernatur.
                  Cumque eveniet dolores expedita, numquam perferendis, praesentium nemo asperiores est, tempora earum quam! Maxime omnis, doloribus nam veniam dolores ullam et deleniti, saepe magni fugit assumenda voluptate sint? Esse, blanditiis?</p>
                <p className="leading-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quas perferendis sit eos dolor similique aliquam incidunt, excepturi modi et, odio est quaerat repudiandae consequatur fugiat accusamus aperiam saepe. Maxime!
                  Dolorum facere atque nisi possimus enim et id odit? Earum deserunt alias ratione! Perferendis in enim voluptatem quam nam ex molestiae laudantium, corporis adipisci! Dolorem minus nisi maiores illum aspernatur.
                  Cumque eveniet dolores expedita, numquam perferendis, praesentium nemo asperiores est, tempora earum quam! Maxime omnis, doloribus nam veniam dolores ullam et deleniti, saepe magni fugit assumenda voluptate sint? Esse, blanditiis?</p>
                <p className="leading-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quas perferendis sit eos dolor similique aliquam incidunt, excepturi modi et, odio est quaerat repudiandae consequatur fugiat accusamus aperiam saepe. Maxime!
                  Dolorum facere atque nisi possimus enim et id odit? Earum deserunt alias ratione! Perferendis in enim voluptatem quam nam ex molestiae laudantium, corporis adipisci! Dolorem minus nisi maiores illum aspernatur.
                  Cumque eveniet dolores expedita, numquam perferendis, praesentium nemo asperiores est, tempora earum quam! Maxime omnis, doloribus nam veniam dolores ullam et deleniti, saepe magni fugit assumenda voluptate sint? Esse, blanditiis?</p>
              </div>
            </div>
          )}
        </div>
      </article>
      <article className="bg-white shadow-fullSides lg:w-7/12 py-5 px-5 lg:pl-8 lg:pr-0 mb-5" onClick={() => setopenArticle(2)}>
        <div className="lg:w-10/12">
          <h3 className="font-normal mb-0">Lorem ipsum dolor sit.</h3>
          {openArticle === 2 && (
            <div className="my-4">
              <div className="animate__animated animate__fadeIn" id={`menu-${2}`}>
                <p className="leading-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quas perferendis sit eos dolor similique aliquam incidunt, excepturi modi et, odio est quaerat repudiandae consequatur fugiat accusamus aperiam saepe. Maxime!
                  Dolorum facere atque nisi possimus enim et id odit? Earum deserunt alias ratione! Perferendis in enim voluptatem quam nam ex molestiae laudantium, corporis adipisci! Dolorem minus nisi maiores illum aspernatur.
                  Cumque eveniet dolores expedita, numquam perferendis, praesentium nemo asperiores est, tempora earum quam! Maxime omnis, doloribus nam veniam dolores ullam et deleniti, saepe magni fugit assumenda voluptate sint? Esse, blanditiis?</p>
                <p className="leading-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quas perferendis sit eos dolor similique aliquam incidunt, excepturi modi et, odio est quaerat repudiandae consequatur fugiat accusamus aperiam saepe. Maxime!
                  Dolorum facere atque nisi possimus enim et id odit? Earum deserunt alias ratione! Perferendis in enim voluptatem quam nam ex molestiae laudantium, corporis adipisci! Dolorem minus nisi maiores illum aspernatur.
                  Cumque eveniet dolores expedita, numquam perferendis, praesentium nemo asperiores est, tempora earum quam! Maxime omnis, doloribus nam veniam dolores ullam et deleniti, saepe magni fugit assumenda voluptate sint? Esse, blanditiis?</p>
                <p className="leading-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quas perferendis sit eos dolor similique aliquam incidunt, excepturi modi et, odio est quaerat repudiandae consequatur fugiat accusamus aperiam saepe. Maxime!
                  Dolorum facere atque nisi possimus enim et id odit? Earum deserunt alias ratione! Perferendis in enim voluptatem quam nam ex molestiae laudantium, corporis adipisci! Dolorem minus nisi maiores illum aspernatur.
                  Cumque eveniet dolores expedita, numquam perferendis, praesentium nemo asperiores est, tempora earum quam! Maxime omnis, doloribus nam veniam dolores ullam et deleniti, saepe magni fugit assumenda voluptate sint? Esse, blanditiis?</p>
              </div>
            </div>
          )}
        </div>
      </article>
      <article className="bg-white shadow-fullSides lg:w-7/12 py-5 px-5 lg:pl-8 lg:pr-0 mb-5" onClick={() => setopenArticle(3)}>
        <div className="lg:w-10/12">
          <h3 className="font-normal mb-0">Lorem ipsum dolor sit.</h3>
          {openArticle === 3 && (
            <div className="my-4">
              <div className="animate__animated animate__fadeIn" id={`menu-${3}`}>
                <p className="leading-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quas perferendis sit eos dolor similique aliquam incidunt, excepturi modi et, odio est quaerat repudiandae consequatur fugiat accusamus aperiam saepe. Maxime!
                  Dolorum facere atque nisi possimus enim et id odit? Earum deserunt alias ratione! Perferendis in enim voluptatem quam nam ex molestiae laudantium, corporis adipisci! Dolorem minus nisi maiores illum aspernatur.
                  Cumque eveniet dolores expedita, numquam perferendis, praesentium nemo asperiores est, tempora earum quam! Maxime omnis, doloribus nam veniam dolores ullam et deleniti, saepe magni fugit assumenda voluptate sint? Esse, blanditiis?</p>
                <p className="leading-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quas perferendis sit eos dolor similique aliquam incidunt, excepturi modi et, odio est quaerat repudiandae consequatur fugiat accusamus aperiam saepe. Maxime!
                  Dolorum facere atque nisi possimus enim et id odit? Earum deserunt alias ratione! Perferendis in enim voluptatem quam nam ex molestiae laudantium, corporis adipisci! Dolorem minus nisi maiores illum aspernatur.
                  Cumque eveniet dolores expedita, numquam perferendis, praesentium nemo asperiores est, tempora earum quam! Maxime omnis, doloribus nam veniam dolores ullam et deleniti, saepe magni fugit assumenda voluptate sint? Esse, blanditiis?</p>
                <p className="leading-6">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia quas perferendis sit eos dolor similique aliquam incidunt, excepturi modi et, odio est quaerat repudiandae consequatur fugiat accusamus aperiam saepe. Maxime!
                  Dolorum facere atque nisi possimus enim et id odit? Earum deserunt alias ratione! Perferendis in enim voluptatem quam nam ex molestiae laudantium, corporis adipisci! Dolorem minus nisi maiores illum aspernatur.
                  Cumque eveniet dolores expedita, numquam perferendis, praesentium nemo asperiores est, tempora earum quam! Maxime omnis, doloribus nam veniam dolores ullam et deleniti, saepe magni fugit assumenda voluptate sint? Esse, blanditiis?</p>
              </div>
            </div>
          )}
        </div>
      </article>
    </section>
  )
}

export default Accordion